<template>
  <div class="home">
    <router-view></router-view>
    <b-card class="mp-0">
      <!-- Search Bar -->

      <!-- <Psearchbar/> -->

      <!-- Tabs -->

      <b-tabs lazy>
        <b-tab title="Salaried Staff">
          <div class="alert-body"><SalarisedStaff /></div>
        </b-tab>

        <b-tab title="Locums">
          <div class="alert-body"><Locumes /></div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import SalarisedStaff from "./salarisedstaff.vue";
import Locumes from "./locumes.vue";
import Psearchbar from "./psearchbar.vue";

import { BCard, BTabs, BTab } from "bootstrap-vue";

export default {
  name: "Home",
  components: {
    SalarisedStaff,
    Locumes,
    Psearchbar,
    BCard,
    BTabs,
    BTab,
  },
};
</script>
