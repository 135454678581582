<template>
  <b-row>
    <!-- Search Bar  -->

    <b-col class="mb-1" cols="12" md="4">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input v-model="filters['search']" placeholder="Search" />
      </b-input-group>
    </b-col>

    <!-- Select Job Role  -->

    <b-col cols="12" md="3">
      <b-form-group>
        <v-select
          v-model="filters['job_role_id']"
          :options="jobRoleFilterOptions"
          :reduce="(jobRoleFilterOptions) => jobRoleFilterOptions.id"
          label="name"
          placeholder="All Roles"
          class="mb-2"
          value="id"
        />
      </b-form-group>
    </b-col>

    <!-- Select Location  -->

    <b-col cols="12" md="2">
      <b-form-group>
        <v-select
          v-model="filters['practice.id']"
          :options="locationFilterOptions"
          :reduce="(locationFilterOptions) => locationFilterOptions.id"
          label="name"
          placeholder="All Practices"
          class="mb-2"
          value="id"
        />
      </b-form-group>
    </b-col>

    <!-- Send Group Message Button -->

    <!-- <b-col class="flex-float-right" cols="12" md="3">
      <b-form-group>
        <b-button
            class="btn-sm-block float-right"
            variant="primary"
        >
          <feather-icon
              icon="SendIcon"
              class="mr-50"
          />
          <span class="align-middle">Send Group Message</span>
        </b-button>
      </b-form-group>
    </b-col> -->
    <b-col cols="12">
      <b-overlay :show="tableLoading" rounded="sm">
        <b-table
          id="table"
          ref="table"
          @row-clicked="myRowClickHandler"
          :items="getSalariedStaffs"
          :current-page="currentPage"
          :per-page="pagination.perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          empty-text="No matching records found"
          :fields="fields"
          primary-key="id"
          class="custom_align mobile_table_css bank-tbl"
          show-empty
          hover
          responsive
        >
          <template #cell(is_archive)="data">
            <b-badge variant="light-danger">{{ data.item.is_archive }}</b-badge>
          </template>
          <template #cell(action)="data">
            <div class="d-flex flex-row">
              <div class="float-left">
                <b-button
                  v-b-tooltip.hover.top="'Archive staff'"
                  variant="gradient-primary mt-1"
                  class="btn-icon"
                  @click="addAgainUser(data.item)"
                  size="sm"
                >
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-col>

    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-start
      "
      cols="12"
      sm="6"
    >
      <!-- <span class="text-muted">Showing 1 to 10 of 11 entries</span> -->
      <span class="text-muted" v-if="pagination.totalRows !== 0"
        >Showing {{ pagination.from }} to {{ pagination.to }} of
        {{ pagination.totalRows }} entries</span
      >
    </b-col>

    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-end
      "
      cols="12"
      sm="6"
    >
      <b-pagination
        v-model="currentPage"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        class="mb-0 mt-1 mt-sm-0"
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import Filter from "@/mixins/FilterMixin";
import MomentMixin from "@/mixins/MomentMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import StaffBankAPI from "@/apis/modules/staffBank";
import { mapGetters } from "vuex";

import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BOverlay,
  BListGroupItem,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BDropdown,
    BOverlay,
    BDropdownItem,
    BListGroupItem,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data() {
    return {
      location: undefined,
      jobRole: undefined,
      currentPage: 1,
      filters: {
        "practice.id": undefined,
        job_role_id: undefined,
      },
      filter: null,
      filterOn: [],
      locationFilterOptions: [],
      jobRoleFilterOptions: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      tableLoading: false,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      fields: [
        {
          key: "first_name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "role",
          label: "role",
          sortable: false,
        },
        {
          key: "location",
          label: "Practice",
          sortable: false,
        },

        {
          key: "telephone",
          label: "telephone",
          sortable: false,
        },
        {
          key: "email",
          label: "email",
          sortable: false,
        },
        {
          key: "is_archive",
          label: "Status",
        },
        {
          key: "action",
          label: "",
        },
        // {
        //   key: 'total_hours_worked',
        //   label: 'Total hours worked',
        //   sortable: true
        // },
        // {
        //   key: 'total_earning',
        //   label: 'Total earnings',
        //   sortable: true
        // }
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    async getLocations() {
      this.locationFilterOptions = (
        await StaffBankAPI.getLocations()
      ).data.data;
    },

    async getJobRoles() {
      this.jobRoleFilterOptions = (
        await StaffBankAPI.getJobRolesWithoutPagination()
      ).data.data;
    },
    async getSalariedStaffs() {
      try {
        this.tableLoading = true;

        if (this.sortBy === "") {
          this.sortDesc = true;
        }

        const Response = await StaffBankAPI.getArchiveSalariedStaff(
          this.currentPage,
          15,
          this.filterQuery,
          this.sortBy,
          this.sortDesc
        );
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          user_id: x.user.id,
          first_name: x.user.first_name + " " + x.user.last_name,
          role: x.job_role !== null ? x.job_role.name : "Practice Admin",
          location: x.practic !== null ? x.practice.name : "N/A",
          telephone: x.user.phone_number ? x.user.phone_number : "N/A",
          email: x.user.email,
          practic_id: x.practice_id,
          is_archive: "Archived",
          // total_hours_worked: 'N/A',
          // total_earning: 'N/A',
        }));
        this.noDataTable = Response.data.data.length;
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;

        this.tableLoading = false;

        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },

    addAgainUser(item) {
      try {
        this.$swal({
          title: "Are you sure you want to Add again this user?",
          text: "",
          imageUrl: require("@/assets/images/icons/publish.png"),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await StaffBankAPI.addAgain(item.user_id, item.practic_id);
            this.$refs.table.refresh();
          }
        });
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    myRowClickHandler(item) {
      this.$router.push(
        `/staff-bank/${item.user_id}/practice/${item.practic_id}`
      );
    },
    dataRefresh() {
      this.$refs.table.refresh();
    },
    filterQueryUpdate() {
      if (this.mounted) this.$refs.table.refresh();
    },
  },
  watch: {
    // location(value) {
    //   if (value[0]) this.filters['practice.id'] = value[0].id
    //   else this.filters['practice.id'] = undefined
    // },
    // jobRole(value) {
    //   if (value[0]){
    //     this.filters['job_role_id'] = value[0].id
    //   }
    //   else{
    //     this.filters['job_role_id'] = undefined
    //   }
    // }
  },
  async mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 1000);
    await this.getLocations();
    await this.getJobRoles();
  },
};
</script>

<style scoped>
</style>