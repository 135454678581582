<template>
  <b-card class="mp-0">
    <!-- Tabs -->

    <b-tabs>
      <b-tab title="Active Users" lazy>
        <div class="alert-body"><ActiveUser /></div>
      </b-tab>
      <b-tab title="Archive Users" lazy>
        <div class="alert-body"><ArchiveUser /></div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

  <script>
import ActiveUser from "./includes/active-users.vue";
import ArchiveUser from "./includes/archive-users.vue";

import { BCard, BTabs, BTab, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Home",
  components: {
    BCard,
    BButton,
    BTabs,
    BTab,
    ActiveUser,
    ArchiveUser,
  },
  directives: {
    Ripple,
  },
};
</script>
