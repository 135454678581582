<template>
  <div class="row">

    <!-- Search Button -->

    <b-col class="mb-1" cols="12" md="4">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon"/>
        </b-input-group-prepend>

        <b-form-input placeholder="Search"/>
      </b-input-group>
    </b-col>

    <!-- Select Location  -->

    <b-col cols="12" md="2">
      <b-form-group>
        <v-select
            v-model="selected1"
            :options="[
                            {title1:'United Kingdom', value:'hdhdh'},
                            {title1:'United States', value:'hdhdh'},
                            {title1:'Sri Lanka', value:'hdhdh'},
                            ]"
            label="title1"
            placeholder="All Roles"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" md="2">
      <b-form-group>
        <v-select
            v-model="selected1"
            :options="[
                            {title1:'United Kingdom', value:'hdhdh'},
                            {title1:'United States', value:'hdhdh'},
                            {title1:'Sri Lanka', value:'hdhdh'},
                            ]"
            label="title1"
            placeholder="All Locations"
        />
      </b-form-group>
    </b-col>

    <!-- Create Programme Button -->



    <b-col class="flex-float-right" cols="12" md="4">
      <b-form-group>
        <b-button
            class="btn-sm-block float-right"
            variant="primary"
        >
          <feather-icon
              icon="SendIcon"
              class="mr-50"
          />
          <span class="align-middle">Send Group Message</span>
        </b-button>
      </b-form-group>
    </b-col>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BRow
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    vSelect,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      option1: [{title1: 'February, 2021'}, {title1: 'January, 2021'}, {title1: 'December, 2020'}, {title1: 'November, 2020'}, {title1: 'Octomber, 2020'},],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

