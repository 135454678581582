import api from '../axios';

export default {
  getSalariedStaff: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/pcn/staff-bank?filter[is_locum]=0&sort=${
        desc ? '' : '-'
      }${sort}&${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  getLocums: (page, paginate, filterQuery) =>
    api.get(
      `api/pcn/staff-bank?filter[is_locum]=1&${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  getLocations: () => api.get('api/pcn/staff-bank/locations'),
  getJobRoles: () => api.get('api/job-roles'),
  getJobRolesWithoutPagination: () => api.get('api/pcn/staff-bank/job-roles'),

  getProfileDetails: (id) => api.get(`api/pcn/staff-bank/${id}`),
  getUnavailabilityAndWorkingHoursDetails: (id) => api.get(`api/pcn/staff-bank/unavailability/${id}`),
  getStaffShifts: (id, page, paginate) =>
    api.get(
      `api/pcn/staff-bank/${id}/shifts?page=${page}&paginate=${paginate}`
    ),

    //This route for get all archived users
    getArchiveSalariedStaff: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/pcn/staff-bank/archived-users?filter[is_locum]=0&sort=${
        desc ? '' : '-'
      }${sort}&${filterQuery}&page=${page}&paginate=${paginate}`
    ),

  removeUser:(user_id,practic_id) =>api.put(`api/pcn/staff-bank/users/${user_id}/remove?auth_practice_id=${practic_id}`),
  addAgain:(user_id,practic_id) =>api.put(`api/pcn/staff-bank/users/${user_id}/add?auth_practice_id=${practic_id}`)
};
