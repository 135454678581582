<template>
  <b-row>

    <!-- Search Bar  -->
    <b-col class="mb-1" cols="12" md="4">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon"/>
        </b-input-group-prepend>

        <b-form-input
          v-model="filters['search']"
          placeholder="Search"/>
      </b-input-group>
    </b-col>

    <!-- Select Job Role  -->

    <b-col cols="12" md="2">
      <b-form-group>
        <v-select
            v-model="filters['job_role_id']"
            :options="jobRoleFilterOptions"
            :reduce="jobRoleFilterOptions => jobRoleFilterOptions.id"
            label="name"
            placeholder="All Roles"
            class="mb-2"
            value="id"
        />
      </b-form-group>
      {{ filters['JobRole_id'] }}
    </b-col>

    <!-- Select Location  -->

    <b-col cols="12" md="2">
      <b-form-group>
        <v-select
            v-model="filters['practice.id']"
            :options="locationFilterOptions"
            :reduce="locationFilterOptions => locationFilterOptions.id"
            label="name"
            placeholder="All Practices"
            class="mb-2"
            value="id"
        />
      </b-form-group>
    </b-col>

    <!-- Send Group Message Button -->

    <!-- <b-col class="flex-float-right" cols="12" md="4">
      <b-form-group>
        <b-button
            class="btn-sm-block float-right"
            variant="primary"
        >
          <feather-icon
              icon="SendIcon"
              class="mr-50"
          />
          <span class="align-middle">Send Group Message</span>
        </b-button>
      </b-form-group>
    </b-col> -->
    <b-col cols="12">
    <b-overlay
        :show="tableLoading"
        rounded="sm"
        >
        <b-table
          id="table"
          ref="table"
          :items="getLocums"
          @row-clicked="myRowClickHandler"
          :current-page="currentPage"
          :per-page="pagination.perPage"
          :fields="fields"
          primary-key="id"
          hover
          responsive
        >
      </b-table>
      <div class="text-center p-5" v-if="locumResponseLength === 0 ">
        <svg width="220" height="220" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z" fill="#F0F8FC"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M111.66 40.3191C114.009 40.3191 115.915 42.2246 115.915 44.5744C115.915 46.9242 114.009 48.8297 111.66 48.8297C109.309 48.8297 107.404 46.9242 107.404 44.5744C107.404 42.2246 109.309 40.3191 111.66 40.3191Z" fill="#DCEEF8"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M123.575 26.7021C126.865 26.7021 129.532 29.3694 129.532 32.6596C129.532 35.9498 126.865 38.617 123.575 38.617C120.284 38.617 117.617 35.9498 117.617 32.6596C117.617 29.3694 120.284 26.7021 123.575 26.7021Z" fill="#DCEEF8"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M105.702 25C106.643 25 107.404 25.7617 107.404 26.7021C107.404 27.6426 106.643 28.4043 105.702 28.4043C104.762 28.4043 104 27.6426 104 26.7021C104 25.7617 104.762 25 105.702 25Z" fill="#DCEEF8"/>
          <g clip-path="url(#clip0)">
            <path d="M111.043 64.4783C104.932 64.4783 99.9564 69.4541 99.9564 75.5652C99.9564 81.6762 104.932 86.6521 111.043 86.6521C117.154 86.6521 122.13 81.6762 122.13 75.5652C122.13 69.4541 117.154 64.4783 111.043 64.4783ZM111.043 82.2173C107.376 82.2173 104.391 79.2328 104.391 75.5652C104.391 71.8975 107.376 68.913 111.043 68.913C114.711 68.913 117.695 71.8975 117.695 75.5652C117.695 79.2328 114.711 82.2173 111.043 82.2173Z" fill="black" stroke="#F0F8FC"/>
            <path d="M49.2723 64.4783C43.1567 64.4783 38.1854 69.4541 38.1854 75.5652C38.1854 81.6762 43.1612 86.6521 49.2723 86.6521C55.3834 86.6521 60.3592 81.6762 60.3592 75.5652C60.3592 69.4541 55.3877 64.4783 49.2723 64.4783ZM49.2723 82.2173C45.6047 82.2173 42.6201 79.2328 42.6201 75.5652C42.6201 71.8975 45.6047 68.913 49.2723 68.913C52.9399 68.913 55.9245 71.8975 55.9245 75.5652C55.9245 79.2328 52.9397 82.2173 49.2723 82.2173Z" fill="black" stroke="#F0F8FC"/>
            <path d="M111.042 91.0869C107.393 91.0869 103.818 92.0847 100.705 93.9696C99.6629 94.6082 99.3257 95.9696 99.9599 97.0163C100.603 98.063 101.96 98.3956 103.007 97.7613C105.423 96.2933 108.2 95.5216 111.042 95.5216C119.602 95.5216 126.564 102.484 126.564 111.043C126.564 112.267 127.558 113.261 128.782 113.261C130.006 113.261 130.999 112.267 130.999 111.043C130.999 100.041 122.045 91.0869 111.042 91.0869Z" fill="black" stroke="#F0F8FC"/>
            <path d="M59.2896 93.965C56.1808 92.0847 52.6065 91.0869 48.9565 91.0869C37.9538 91.0869 29 100.041 29 111.043C29 112.267 29.9935 113.261 31.2175 113.261C32.4415 113.261 33.4349 112.267 33.4349 111.043C33.4347 102.484 40.3975 95.5216 48.9565 95.5216C51.7993 95.5216 54.5753 96.2933 56.988 97.7568C58.0301 98.391 59.396 98.0584 60.0347 97.0117C60.6733 95.965 60.3361 94.6036 59.2896 93.965Z" fill="black" stroke="#F0F8FC"/>
            <path d="M79.9992 82.2173C64.1048 82.2173 51.173 95.1491 51.173 111.043C51.173 112.267 52.1665 113.261 53.3905 113.261C54.6145 113.261 55.608 112.267 55.608 111.043C55.608 97.5927 66.5485 86.6522 79.9992 86.6522C93.4499 86.6522 104.39 97.5927 104.39 111.043C104.39 112.267 105.384 113.261 106.608 113.261C107.832 113.261 108.825 112.267 108.825 111.043C108.825 95.1491 95.8935 82.2173 79.9992 82.2173Z" fill="#1B9AAA" stroke="#F0F8FC"/>
            <path d="M80.0002 46.739C71.4411 46.739 64.4784 53.7015 64.4784 62.2608C64.4784 70.8201 71.4409 77.7826 80.0002 77.7826C88.5594 77.7826 95.522 70.8199 95.522 62.2608C95.522 53.7018 88.5594 46.739 80.0002 46.739ZM80.0002 73.3477C73.8891 73.3477 68.9133 68.3719 68.9133 62.2608C68.9133 56.1498 73.8891 51.1739 80.0002 51.1739C86.1112 51.1739 91.087 56.1498 91.087 62.2608C91.087 68.3719 86.1114 73.3477 80.0002 73.3477Z" fill="#1B9AAA" stroke="#F0F8FC"/>
            <path d="M60.8751 43.0252C60.8751 43.599 60.4926 43.9815 59.9189 43.9815H54.1814V49.719C54.1814 50.2927 53.7989 50.6752 53.2251 50.6752C52.6514 50.6752 52.2689 50.2927 52.2689 49.719V43.9815H46.5314C45.9576 43.9815 45.5751 43.599 45.5751 43.0252C45.5751 42.4515 45.9576 42.069 46.5314 42.069H52.2689V36.3315C52.2689 35.7577 52.6514 35.3752 53.2251 35.3752C53.7989 35.3752 54.1814 35.7577 54.1814 36.3315V42.069H59.9189C60.4926 42.069 60.8751 42.4515 60.8751 43.0252Z" fill="#1B9AAA" stroke="#1B9AAA"/>
            <mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="45" y="35" width="16" height="16">
              <path d="M60.8751 43.0252C60.8751 43.599 60.4926 43.9815 59.9189 43.9815H54.1814V49.719C54.1814 50.2927 53.7989 50.6752 53.2251 50.6752C52.6514 50.6752 52.2689 50.2927 52.2689 49.719V43.9815H46.5314C45.9576 43.9815 45.5751 43.599 45.5751 43.0252C45.5751 42.4515 45.9576 42.069 46.5314 42.069H52.2689V36.3315C52.2689 35.7577 52.6514 35.3752 53.2251 35.3752C53.7989 35.3752 54.1814 35.7577 54.1814 36.3315V42.069H59.9189C60.4926 42.069 60.8751 42.4515 60.8751 43.0252Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
              <path d="M60.8751 43.0252C60.8751 43.599 60.4926 43.9815 59.9189 43.9815H54.1814V49.719C54.1814 50.2927 53.7989 50.6752 53.2251 50.6752C52.6514 50.6752 52.2689 50.2927 52.2689 49.719V43.9815H46.5314C45.9576 43.9815 45.5751 43.599 45.5751 43.0252C45.5751 42.4515 45.9576 42.069 46.5314 42.069H52.2689V36.3315C52.2689 35.7577 52.6514 35.3752 53.2251 35.3752C53.7989 35.3752 54.1814 35.7577 54.1814 36.3315V42.069H59.9189C60.4926 42.069 60.8751 42.4515 60.8751 43.0252Z" fill="#1B9AAA"/>
              <mask id="mask1" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="45" y="35" width="16" height="16">
                <path d="M60.8751 43.0252C60.8751 43.599 60.4926 43.9815 59.9189 43.9815H54.1814V49.719C54.1814 50.2927 53.7989 50.6752 53.2251 50.6752C52.6514 50.6752 52.2689 50.2927 52.2689 49.719V43.9815H46.5314C45.9576 43.9815 45.5751 43.599 45.5751 43.0252C45.5751 42.4515 45.9576 42.069 46.5314 42.069H52.2689V36.3315C52.2689 35.7577 52.6514 35.3752 53.2251 35.3752C53.7989 35.3752 54.1814 35.7577 54.1814 36.3315V42.069H59.9189C60.4926 42.069 60.8751 42.4515 60.8751 43.0252Z" fill="white"/>
              </mask>
              <g mask="url(#mask1)">
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="102" height="102" fill="white" transform="translate(29 29)"/>
            </clipPath>
          </defs>
        </svg>
        <br>
        <div class="mt-lg-1 text-large">No Locums</div>
      </div>
      </b-overlay>
    </b-col>


    <!-- Pagination -->

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <!-- <span class="text-muted">Showing 1 to 10 of 187 entries</span> -->
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
    </b-col>

    <!-- Pagination -->

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

      <b-pagination
        v-model="currentPage"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        class="mb-0 mt-1 mt-sm-0 "
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item"
      >

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>

      </b-pagination>

    </b-col>
  </b-row>
</template>

<script>
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import StaffBankAPI from '@/apis/modules/staffBank'

import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BOverlay,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BDropdown,
    BOverlay,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data() {
    return {
      locumResponseLength:0,
      location: undefined,
      jobRole: undefined,
      locationFilterOptions:[],
      jobRoleFilterOptions: [],
      currentPage: 1,
      filters: {
        'practice.id' : undefined,
        'job_role_id' : undefined,
      },
      filter: null,
      filterOn: [],
      tableLoading: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      fields: [
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'role',
          label: 'role',
          sortable: true
        },
        {
          key: 'location',
          label: 'Location',
          sortable: true
        },

        {
          key: 'telephone',
          label: 'telephone',
          sortable: true
        },
        {
          key: 'email',
          label: 'email',
          sortable: true
        },
        {
          key: 'total_hours_worked',
          label: 'Total hours worked',
          sortable: true
        },
      ],
      items: []
    }
  },
  methods: {
    async getLocations() {
      this.locationFilterOptions = (await StaffBankAPI.getLocations()).data.data
    },
    async getJobRoles() {
      this.jobRoleFilterOptions = (await StaffBankAPI.getJobRoles()).data.data
    },

    async getLocums(){
      try{
        this.tableLoading = true

        const Response = await StaffBankAPI.getLocums(this.currentPage, 15, this.filterQuery)
        this.locumResponseLength = Response.data.data.length

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          user_id: x.user.id,
          name: x.user.first_name + ' ' + x.user.last_name,
          role: x.job_role.name,
          location: x.practice.name,
          telephone: x.user.phone_number ? x.user.phone_number : 'N/A',
          email: x.user.email,
          total_hours_worked: 'N/A',
          total_earning: 'N/A',
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        return dataArray

      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    myRowClickHandler(item) {
     this.$router.push(`/staff-bank/${item.user_id}`)
    },
    dataRefresh(){
      this.$refs.table.refresh()
    },
    filterQueryUpdate () {
      if (this.mounted) this.$refs.table.refresh()
    },
  },
  watch:{
    // location(value) {
    //   if (value[0]) this.filters['practice.id'] = value[0].id
    //   else this.filters['practice.id'] = undefined
    // },
    // jobRole(value) {
    //   if (value[0]){
    //     this.filters['job_role_id'] = value[0].id
    //   }
    //   else{
    //     this.filters['job_role_id'] = undefined
    //   }
    // }
  },
  async mounted() {
    setTimeout(() => {
      this.mounted = true
    }, 1000)
    await this.getLocations()
    await this.getJobRoles()
  },

}
</script>
<style>

</style>
